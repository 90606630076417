<template>
    <!-- <v-dialog value="true" scrollable persistent> -->
    <v-dialog value="true" persistent @keydown.esc="close">
        <v-card v-if="items.length > 0" height="90vh" class="d-flex flex-column">
            <v-card-title class="headline primary white--text">
                <v-row>
                    <v-col cols="8">
                        <div id="banner">
                            <img :src="require('@/assets/images/logoWhite.png')" alt="INCHARGE" />
                        </div>
                    </v-col>
                    <v-col cols="4" class="text-right">
                        <v-btn icon dark @click="startScreen">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text v-if="itemToShow" class="my-4 text-h6 text-center">
                <ReceiptItem :item="itemToShow" />
            </v-card-text>

            <v-card-text v-else class="my-4 text-h6 text-center">
                Encontramos mais de um carregamento
                <br>
                <br>
                {{ $moment(date).format('DD/MM/YYYY') }}
                <br>
                <br>

                <v-btn v-for="(item, i) in items" :key="i" color="success" @click="selectItem(item)" class="my-2" style="min-width: 100%;">
                    <v-icon dark left>electrical_services</v-icon>
                    {{ item.charger }}

                    <v-spacer />
                    R$ {{ item.value }}

                    <v-spacer />
                    <v-icon dark class="mx-2">watch_later</v-icon>
                    {{ $moment(item.dateStart).format('HH:mm:ss') }}
                </v-btn>

            </v-card-text>

            <v-spacer />

            <v-card-actions class="justify-center d-print-none">
                <v-btn v-if="itemToShow" color="success" @click="createPDF(itemToShow)" style="width: 50%;" :disabled="sending">
                    <v-icon dark left>print</v-icon>
                    Gerar arquivo
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-else>
            <v-card-title class="headline primary white--text">
                <v-row>
                    <v-col cols="8">
                        Recibos
                    </v-col>
                    <v-col cols="4" class="text-right">
                        <v-btn icon dark @click="close">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="my-4 text-h6 text-center">
                Data do carregamento
                <div class="ma-auto position-relative mb-4" style="max-width: 300px">
                    <v-date-picker v-model="date" color="primary lighten-1 " elevation="2" :disabled="sending" />
                </div>
            </v-card-text>
            <v-card-text class="my-4 text-center">
                4 últimos digítos do cartão utilizado
                <div class="ma-auto position-relative" style="max-width: 300px">
                    <v-otp-input length="4" type="number" v-model="code" @finish="onConfirm" :disabled="sending" />
                </div>
            </v-card-text>

            <v-divider />

            <v-card-actions class="justify-center">
                <v-btn color="success" @click="onConfirm" style="min-width: 25%;" :disabled="sending" :loading="sending">
                    <v-icon dark left>check_circle</v-icon>
                    Buscar Recibo
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import receipt from "@/pdf/receipt";
import ReceiptItem from "./ReceiptItem.vue";

export default {
    components: { ReceiptItem },

    data() {
        return {
            receipt,
            items: [],
            itemToShow: null,
            sending: false,
            code: null,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        };
    },

    methods: {
        selectItem(item) {
            // this.createPDF(item);
            this.itemToShow = item;
        },
        startScreen() {
            this.items = [];
            this.itemToShow = null;
        },
        createPDF(receiptItem) {
            this.sending = true;

            setTimeout(() => {
                console.log(receiptItem)
                let doc = receipt.create(receiptItem);
                // doc.output("report.pdf");
                // window.open(doc.output("bloburl"), "_blank");
                doc.output("dataurlnewwindow", { filename: "recibo_incharge.pdf" })

                // const blobUrl = doc.output("bloburi", {
                //     filename: "report.pdf"
                // });
                // window.open(blobUrl, "_blank");

                // var string = doc.output('datauristring', { filename: "recibo_incharge.pdf" });
                // var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
                // var x = window.open();
                // x.document.open();
                // x.document.write(embed);
                // x.document.close();

                this.sending = false;
            }, 100);
        },
        onConfirm() {
            if (this.code.length == 4) {
                this.sending = true;
                this.$http
                    .postAlternativeAPI("api/v2/payment/receipt", { code: this.code, date: this.date })
                    .then((result) => {
                        if (result) {
                            if (result.length == 1) {
                                this.itemToShow = result[0];
                                this.items = result;
                            } else if (result.length > 0) {
                                this.items = result;
                            } else {
                                this.$eventHub.$emit("msgError", "Nenhuma informação localizada com esses dados.");
                            }
                        }
                    })
                    .catch((error) => {
                        this.$eventHub.$emit("msgError", error.message ? error.message : "Não foi possível enviar sua solicitação.");
                    }).finally(() => this.sending = false);
            } else {
                this.$eventHub.$emit("msgError", "Código inválido");
            }
        },
        changeDate(event) {
            this.dates = event;
        },
        close() {
            this.$router.replace({ path: "/" });
        }
    },
}
</script>
<style scoped>
#banner {
    height: 16vh;
    background-color: var(--v-primary-base);
}

#banner img {
    width: auto;
    height: 14vh;
    margin-top: 1vh;
}
</style>